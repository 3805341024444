<template>
  <section class="applicant-proposal-formalized">
    <p
      class="applicant-proposal-formalized__description"
    >
      {{
        $t(`${langPath}.proposalDescription`,
           { name: proposal.createdByName })
      }}
    </p>
    <section class="applicant-proposal-formalized__proposal">
      <section class="applicant-proposal-formalized__proposal-field">
        <h6 class="applicant-proposal-formalized__proposal-title">
          {{ $t(`${langPath}.startDate`) }}
        </h6>
        <p
          class="applicant-proposal-formalized__proposal-value"
          data-test="applicant-formalized-start-date"
        >
          {{ startDate }}
        </p>
      </section>
      <section
        v-if="proposal.jobSalary"
        class="applicant-proposal-formalized__proposal-field"
      >
        <h6 class="applicant-proposal-formalized__proposal-title">
          {{ $t(`${langPath}.salary`) }}
        </h6>
        <p
          class="applicant-proposal-formalized__proposal-value"
          data-test="applicant-formalized-salary"
        >
          {{ proposal.jobSalary | brMoneyMask }}
        </p>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ApplicantProposalFormalized',
  props: {
    proposal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    startDate () {
      return this.proposal.jobStartDate
        ? this.$moment(this.proposal.jobStartDate).format('DD/MM/YYYY')
        : ''
    }
  }
}
</script>

<style lang="scss">
.applicant-proposal-formalized {
  &__description {
    @extend %body-text1;

    margin-top: $base*3;
  }

  &__proposal {
    display: flex;
    margin-top: $base*8;
  }

  &__proposal-field {
    margin-right: $base*16;
  }

  &__proposal-title {
    @extend %bold;
  }

  &__proposal-value {
    margin-top: $base*2;
  }
}
</style>
