<template>
  <section class="applicant-proposal-awaiting">
    <p
      data-test="applicant-awaiting-description"
      class="applicant-proposal-awaiting__description"
    >
      {{ $t(`${langPath}.proposalDescription`) }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'ApplicantProposalAwaiting',
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
  .applicant-proposal-awaiting {
    &__description {
      @extend %body-text1;

      margin-top: $base*3;
    }
  }
</style>
