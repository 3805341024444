<template>
  <section class="applicant-proposal">
    <component
      :is="proposalState"
      :applicant="applicant"
      :candidate-name="candidateName"
      :candidate-source="candidateSource"
      :proposal="applicant && applicant.proposal"
    />
  </section>
</template>

<script>
import ApplicantProposalAccepted from './components/ApplicantProposalAccepted'
import ApplicantProposalAwaiting from './components/ApplicantProposalAwaiting'
import ApplicantProposalChoose from './components/ApplicantProposalChoose'
import ApplicantProposalFormalized
  from './components/ApplicantProposalFormalized'
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantProposal',
  components: {
    ApplicantProposalAccepted,
    ApplicantProposalAwaiting,
    ApplicantProposalChoose
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...applicantsHelper.mapGetters(['applicant']),
    candidate () { return this.applicant?.candidate },
    candidateName () { return this.candidate?.name },
    candidateSource () { return this.candidate?.source },
    proposalState () {
      if (this.applicant?.currentState === 'accepted_proposal') {
        if (this.applicant?.proposal?.onlyFormalized) {
          return ApplicantProposalFormalized
        }
        return ApplicantProposalAccepted
      }
      if (this.applicant?.currentState === 'awaiting_proposal') {
        return ApplicantProposalAwaiting
      }
      return ApplicantProposalChoose
    }
  },
  created () {
    if (this.$route.query.referral === 'external') {
      const { referral, ...queryWithoutReferral } = this.$route.query

      this.$smartlook('track', 'clicked_proposal_invites')
      this.$router.replace({ query: queryWithoutReferral })
    }
  }
}
</script>

<style lang="scss">
.applicant-proposal {
  &__title { @extend %subtitle1; }
}
</style>
