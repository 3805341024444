<template>
  <section class="applicant-proposal-accepted">
    <p
      data-test="applicant-accepted-description"
      class="applicant-proposal-accepted__description"
    >
      {{ $t(`${langPath}.proposalDescription`, { name: candidateName }) }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'ApplicantProposalAccepted',
  props: {
    candidateName: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.applicant-proposal-accepted {
  &__description {
    @extend %body-text1;

    margin-top: $base*3;
  }
}
</style>
